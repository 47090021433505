<template>
  <b-container fluid class="bg-white py-5 documentation">
    <b-row class="text-center mb-4" align-h="center" v-if="loading">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row v-if="!loading" class="bg-white">
      <b-col>
          <b-container class="bg-white">
            <b-row>
              <b-col>
                <p>
                  <b-link class="mr-4" :to="{ name: 'Suggestrules' }">all rules</b-link>
                  <b-button class="mr-4" v-if="permission.edit" variant="outline-primary" :to="{ name: 'FormEdit', params: { id: item.id, model: 'suggestrule' }}">Edit</b-button>
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h1 class="my-4">
                  {{item.name}} - {{item.id}}
                </h1>
                <div class="mb-2"><strong>Active</strong></div>
                <div class="mb-4">{{item.active}}</div>
                <div class="mb-2"><strong>Regext title</strong></div>
                <div class="mb-4">{{item.regexTitle}}</div>
                <div class="mb-2"><strong>Regext body</strong></div>
                <div class="mb-4">{{item.regexBody}}</div>
                <div class="mb-2"><strong>Regext title exclude</strong></div>
                <div class="mb-4">{{item.regexTitleExclude}}</div>
                <div class="mb-2"><strong>Regext body exclude</strong></div>
                <div class="mb-4">{{item.regexBodyExclude}}</div>
                <div class="mb-2"><strong>Sources included</strong></div>
                <div class="mb-4">
                  <div v-if="Array.isArray(item.publishers) && item.publishers.length > 0">
                    <span v-for="publisher in item.publishers" :key="'publisher'+publisher">{{publisher}}, </span>
                  </div>
                  <div v-else>all included</div>
                </div>
                <div class="mb-2"><strong>Sources removed</strong></div>
                <div class="mb-4">
                  <div v-if="Array.isArray(item.publishersRemove) && item.publishersRemove.length > 0">
                    <span v-for="publisher in item.publishersRemove" :key="'publisher'+publisher">{{publisher}}, </span>
                  </div>
                  <div v-else>none removed</div>
                </div>
                <div class="mb-2"><strong>Test or tag</strong></div>
                <b-form inline class="mb-4">
                  news to test:
                  <b-form-input class="ml-2" v-model="target" type="number"></b-form-input>
                  <b-button class="ml-2" variant="outline-primary" @click="testRule">Test</b-button>
                  <b-button class="ml-4" variant="outline-primary" @click="runRule">Run</b-button>
                </b-form>
                <div class="mb-2"><strong>Inspect removed items</strong></div>
                <b-form inline class="mb-4">
                  start: <b-form-input class="ml-2 mr-4" v-model="dateStart" type="date"></b-form-input>
                  end: <b-form-input class="ml-2 mr-4" v-model="dateEnd" type="date"></b-form-input>
                  <b-button class="ml-2" variant="outline-primary" @click="showRemoved">Show</b-button>
                </b-form>
              </b-col>
            </b-row>
          </b-container>
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-if="loadingTest">
      <b-col>
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
        <h1>loading test</h1>
        <h2>target: {{target}}</h2>
        <h2>processed: {{processed}}</h2>
        <h2>results: {{testResults.length}}</h2>
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-if="showRun">
      <b-col>
        <div v-if="loadingRun">
          <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
        </div>
        <h1>running tagging</h1>
        <h2>target: {{target}}</h2>
        <h2>processed: {{processed}}</h2>
        <h2 v-if="!loadingRun">finished tagging</h2>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="!loadingTest & testResults.length > 0">
      <b-col>
        target: {{target}}
        processed: {{processed}}
        results: {{testResults.length}}
        <b-card class="mb-3" v-for="item in testResults" :key="item.uid">
          <publication
            :hideLink="false"
            :propItem="item.publication"
            :key="item.publication.id"
            :username="user.username"
            :tagsProp="tags"
            :permissionAddArticle="permissionAddArticle"
            :permissionAddRisk="permissionAddRisk"
            :permissionAddTag="permissionAddTag"
            :permissionArticlesWip="permissionArticlesWip"
            :permissionDeletePublication="permissionDeletePublication"
            :permissionInsights="permissionInsights"
            :permissionReadTagsPanel="permissionReadTagsPanel"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-if="loadingRemoved">
      <b-col>
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row v-if="removed.length > 0 && !loadingRemoved">
      <b-col>
        <b-card class="mb-3" v-for="item in removed" :key="'publication-' + item.id">
          <publication
            :hideLink="false"
            :propItem="item"
            :key="item.id"
            :username="user.username"
            :tagsProp=tags
            :permissionAddArticle="permissionAddArticle"
            :permissionAddRisk="permissionAddRisk"
            :permissionAddTag="permissionAddTag"
            :permissionArticlesWip="permissionArticlesWip"
            :permissionDeletePublication="permissionDeletePublication"
            :permissionInsights="permissionInsights"
            :permissionReadTagsPanel="permissionReadTagsPanel"
          />
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import _ from 'lodash'
import ac from '@/libs/accesscontrol'
import moment from 'moment'
import Publication from '@/components/PublicationNew'

export default {
  name: 'Rule',
  components: {
    Publication
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.permission.edit = ac.can(this.user.acgroups).updateAny('suggestrule').granted
    this.permissionAddArticle = ac.can(this.user.acgroups).createAny('article').granted
    this.permissionAddRisk = true
    this.permissionAddTag = ac.can(this.user.acgroups).createAny('newstag').granted
    this.permissionArticlesWip = ac.can(this.user.acgroups).createAny('articleWorkflowEdit').granted
    this.permissionReadAnalytics = ac.can(this.user.acgroups).readAny('analytics').granted
    this.permissionInsights = true
    this.permissionReadTagsPanel = ac.can(this.user.acgroups).readAny('tagspanel').granted
    if (this.$config.BUILD === 'external') {
      this.permissionAddRisk = false
      this.permissionAddTag = false
      this.permissionDeletePublication = false
      this.permissionInsights = ac.can(this.user.acgroups).readAny('article').granted
      this.permissionReadAnalytics = false
      this.permissionReadTagsPanel = false
    }
    this.load()
  },
  data () {
    return {
      dateEnd: moment().add(1, 'days').format('YYYY-MM-DD'),
      dateStart: moment().subtract(3, 'months').format('YYYY-MM-DD'),
      loading: true,
      loadingRemoved: false,
      loadingTest: false,
      item: null,
      moment: moment,
      permission: {
        delete: false,
        edit: false
      },
      permissionAddRisk: false,
      permissionAddTag: false,
      permissionArticlesWip: false,
      permissionDeletePublication: false,
      permissionReadAnalytics: false,
      permissionReadTagsPanel: false,
      processed: 0,
      removed: [],
      showRun: false,
      tags: [],
      target: 20000,
      testResults: []
    }
  },
  methods: {
    load: async function () {
      this.showRun = false
      this.$stat.log({ page: 'doc', action: 'open doc', model: 'doc', model_id: this.$route.params.id })
      this.loading = true
      try {
        this.item = await this.$Amplify.API.get('cosmos', `/standard/suggestrule/item/${this.$route.params.id}`)
        if (this.item.publishers !== null) {
          this.item.publishers = JSON.parse(this.item.publishers)
        }
        if (this.item.publishersRemove !== null) {
          this.item.publishersRemove = JSON.parse(this.item.publishersRemove)
        }
        document.title = this.item.name
        this.loading = false
      } catch (e) {
        this.$logger.warn(e)
      }
    },
    runRule: async function () {
      this.showRun = true
      this.loadingRun = true
      this.processed = 0
      try {
        const limit = 1000
        let offset = 0
        let page = 0
        do {
          offset = page * limit
          await this.$Amplify.API.get('cosmos', `/suggestrules/run/rule/${this.$route.params.id}/limit/${limit}/offset/${offset}`)
          page = page + 1
          this.processed = page * limit
          await this.$nextTick()
        } while (this.processed < this.target)
        this.loadingRun = false
        await this.$nextTick()
      } catch (e) {
        this.$logger.warn(e)
      }
    },
    showRemoved: async function () {
      try {
        this.showRun = false
        this.loadingRemoved = true
        this.removed = []
        this.removed = await this.$Amplify.API.get('cosmos', `/suggestrules/removed/rule/${this.item.id}/start/${this.dateStart}/end/${this.dateEnd}`)
        this.loadingRemoved = false
      } catch (e) {
        this.$logger.warn(e)
      }
    },
    testRule: async function () {
      this.showRun = false
      this.loadingTest = true
      this.processed = 0
      this.testResults = []
      this.tags = await this.$Amplify.API.get('cosmos', '/tags')
      try {
        const limit = 1000
        let testResults = []
        let offset = 0
        let response = []
        let page = 0
        do {
          offset = page * limit
          response = await this.$Amplify.API.get('cosmos', `/suggestrules/dryrun/rule/${this.$route.params.id}/limit/${limit}/offset/${offset}`)
          testResults = _.concat(this.testResults, response)
          this.testResults = testResults
          page = page + 1
          this.processed = page * limit
          await this.$nextTick()
        } while (this.processed < this.target)
        this.loadingTest = false
      } catch (e) {
        this.$logger.warn(e)
      }
    }
  },
  watch: {
    '$route.params.id': function () {
      this.load()
    }
  }
}
</script>

<style>
.documentation h2 {
  font-size: 1.7em;
  font-weight: 500;
  margin-top: 2em;
  margin-bottom: 1.2em;
}
.documentation h3 {
  font-size: 1.2em;
  font-weight: 600;
  margin-top: 2em;
  margin-bottom: 1.2em;
}
</style>
